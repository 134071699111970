import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer } from '@elastic/eui';
import VideoNewCameraTool from 'components/Quick_Installation/Alternative_IP_Scanner/YoutubeVideoCardNewCameraTool';
import VideoWindowsSD from 'components/Quick_Installation/Alternative_IP_Scanner/YoutubeVideoCardSDCardWin';
import VideoMacSD from 'components/Quick_Installation/Alternative_IP_Scanner/YoutubeVideoCardSDCardMacOS';
import VideoWindowsDNS from 'components/Quick_Installation/Alternative_IP_Scanner/YoutubeVideoCardDomainWin';
import VideoMacDNS from 'components/Quick_Installation/Alternative_IP_Scanner/YoutubeVideoCardDomainMacOS';
import ScannerCards from 'components/Quick_Installation/Alternative_IP_Scanner/ScannerCards';
import NavButtons from 'components/Quick_Installation/NavButtons';
import ForumBox from 'components/Quick_Installation/Alternative_IP_Scanner/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IP Camera Search Tools",
  "path": "/Quick_Installation/Alternative_IP_Scanner/",
  "dateChanged": "2017-09-26",
  "author": "Mike Polinowski",
  "excerpt": "Find your Camera after connecting it to your local Network",
  "image": "./QI_SearchThumb_AlternativeIPScanner_eng.png",
  "social": "/images/Search/QI_SearchThumb_AlternativeIPScanner_eng.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_QI-Alternative_IP_Scanner_white.webp",
  "chapter": "Installation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">








    <SEOHelmet title='IP Camera Search Tools' dateChanged='2017-09-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='Find your Camera after connecting it to your local Network' image='/images/Search/QI_SearchThumb_AlternativeIPScanner_eng.png' twitter='/images/Search/QI_SearchThumb_AlternativeIPScanner_eng.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Schnell_Installation/Alternative_IP_Scanner/' locationFR='/fr/Quick_Installation/Alternative_IP_Scanner/' crumbLabel="IP Scanner" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "ip-camera-search-tools",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#ip-camera-search-tools",
        "aria-label": "ip camera search tools permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IP Camera Search Tools`}</h1>
    <h2 {...{
      "id": "instar-camera-tool",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#instar-camera-tool",
        "aria-label": "instar camera tool permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR Camera Tool`}</h2>
    <p>{`The easiest way to search our camera inside your local network is our INSTAR Camera Tool for `}<a parentName="p" {...{
        "href": "https://wiki.instar.com/dl/Camera_Tool/"
      }}>{`Windows`}</a>{`, `}<OutboundLink to="https://itunes.apple.com/de/app/instar-camera-tool/id584435076?mt=12#" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">{`macOS`}</OutboundLink>{` and `}<a parentName="p" {...{
        "href": "https://wiki.instar.com/dl/Camera_Tool/"
      }}>{`LINUX`}</a>{`.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <VideoNewCameraTool mdxType="VideoNewCameraTool" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "searching-for-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#searching-for-cameras",
        "aria-label": "searching for cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Searching for Cameras`}</h3>
    <p>{`When you start the INSTAR Camera Tool it will automatically search for INSTAR IP cameras on your local network. You can click on the `}<strong parentName="p">{`Search`}</strong>{` button to initiate another scan. Or activate the `}<strong parentName="p">{`Automatic Search`}</strong>{` to have the tool search for new cameras in an interval:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a1f9e520eea60d78f499411b28094df0/248b0/INSTAR_Camera_Tool_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "35.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABOklEQVQoz1VQ7XKDIBD0/V+u/2ImtWojCn6ACqISjNu560zaMLOzyx0styRKKrRtCz1oWGsZ27Zh33eEEF76Ddufpj5hXVd475FkdYdUdPjqJnxk3ygGi0I7pKLFte5Q24Cr6PCpDOM+edzkgPvokSmD0izI+xnVvKMyC5JGSkgp8TxPqFbhESNPKZVCVVU4jif6vsegNYwx8OsKrTWzWxbEePCEdN+MIxKlFOq65nhKSWYanUyapkGMEcPQs9k4Go5mjGZ2znJ/WRac58m1hC41suGCkg32EPhg13X8wHEcb4bb9mtITEbUD/sOWmw4TtMrCjHFmK3FOE7QxmDxnqPQuWmaYJ1jTTzPlk2dc3g8IrY9ICnLEkVR4Ha7IU1TXC4XZFnGmup5nkMIwd8iRP1Pi9eeQEnp+34AoPsSWgAFZ2sAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a1f9e520eea60d78f499411b28094df0/e4706/INSTAR_Camera_Tool_01.avif 230w", "/en/static/a1f9e520eea60d78f499411b28094df0/d1af7/INSTAR_Camera_Tool_01.avif 460w", "/en/static/a1f9e520eea60d78f499411b28094df0/7f308/INSTAR_Camera_Tool_01.avif 920w", "/en/static/a1f9e520eea60d78f499411b28094df0/c300c/INSTAR_Camera_Tool_01.avif 1316w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a1f9e520eea60d78f499411b28094df0/a0b58/INSTAR_Camera_Tool_01.webp 230w", "/en/static/a1f9e520eea60d78f499411b28094df0/bc10c/INSTAR_Camera_Tool_01.webp 460w", "/en/static/a1f9e520eea60d78f499411b28094df0/966d8/INSTAR_Camera_Tool_01.webp 920w", "/en/static/a1f9e520eea60d78f499411b28094df0/c5455/INSTAR_Camera_Tool_01.webp 1316w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a1f9e520eea60d78f499411b28094df0/81c8e/INSTAR_Camera_Tool_01.png 230w", "/en/static/a1f9e520eea60d78f499411b28094df0/08a84/INSTAR_Camera_Tool_01.png 460w", "/en/static/a1f9e520eea60d78f499411b28094df0/c0255/INSTAR_Camera_Tool_01.png 920w", "/en/static/a1f9e520eea60d78f499411b28094df0/248b0/INSTAR_Camera_Tool_01.png 1316w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a1f9e520eea60d78f499411b28094df0/c0255/INSTAR_Camera_Tool_01.png",
              "alt": "INSTAR Camera Tool",
              "title": "INSTAR Camera Tool",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on the `}<em parentName="p">{`three-dots`}</em>{` button next to your camera name to `}<strong parentName="p">{`Rename`}</strong>{` or `}<strong parentName="p">{`Reboot`}</strong>{` your camera. Or to create a desktop shortcut to start your camera's webUI in your web browser:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d57e85c09714be450262faea5904d8ce/078fe/INSTAR_Camera_Tool_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "35.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAA7rAAAO6wFxzYGVAAABKElEQVQoz01R2W6EMAzk//+sj32oKrVqOcORhAAhCeVYTTVud7VIlh0f4/GQ9X0Pay1CCNi2H2zbhuM4cJ6n2HP8/H72NM7RMq01aEZrFHmOZVnEhmHAODrEGCWe5xnOOYQYHwTGcYT3HtM0Sx9zWVVVyIsSi/coq1rAuKAoCnznObaUUJUlBq3Rto3Ulfrrc1wYgoDSc1H2WTV4efvAe9ni9auCnRbEsKIfBnRdh+s8ocmQAM7KWaM14td1lXPJ7nZdCCEia5USRtSjbZU0cKOxVvK32wVrjQxPk5O6cyP2Y0dKSer7voOfaEiatJQijDGiA7WiPgTkKfTMEZin3j1z6+qFAEHJNLsPNk2Duq5BTfkTlFIwxkpMdhzy//7x9l60Fw1jFNBf/5YVBAranmMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d57e85c09714be450262faea5904d8ce/e4706/INSTAR_Camera_Tool_02.avif 230w", "/en/static/d57e85c09714be450262faea5904d8ce/d1af7/INSTAR_Camera_Tool_02.avif 460w", "/en/static/d57e85c09714be450262faea5904d8ce/7f308/INSTAR_Camera_Tool_02.avif 920w", "/en/static/d57e85c09714be450262faea5904d8ce/68819/INSTAR_Camera_Tool_02.avif 934w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d57e85c09714be450262faea5904d8ce/a0b58/INSTAR_Camera_Tool_02.webp 230w", "/en/static/d57e85c09714be450262faea5904d8ce/bc10c/INSTAR_Camera_Tool_02.webp 460w", "/en/static/d57e85c09714be450262faea5904d8ce/966d8/INSTAR_Camera_Tool_02.webp 920w", "/en/static/d57e85c09714be450262faea5904d8ce/7d4d8/INSTAR_Camera_Tool_02.webp 934w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d57e85c09714be450262faea5904d8ce/81c8e/INSTAR_Camera_Tool_02.png 230w", "/en/static/d57e85c09714be450262faea5904d8ce/08a84/INSTAR_Camera_Tool_02.png 460w", "/en/static/d57e85c09714be450262faea5904d8ce/c0255/INSTAR_Camera_Tool_02.png 920w", "/en/static/d57e85c09714be450262faea5904d8ce/078fe/INSTAR_Camera_Tool_02.png 934w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d57e85c09714be450262faea5904d8ce/c0255/INSTAR_Camera_Tool_02.png",
              "alt": "INSTAR Camera Tool",
              "title": "INSTAR Camera Tool",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If the button is shown with a `}<em parentName="p">{`refresh`}</em>{` icon you can perform a firmware update with the selected camera:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4cc395b4c6890e594af144bc503ebc30/078fe/INSTAR_Camera_Tool_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "35.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAA7rAAAO6wFxzYGVAAABJElEQVQoz02Q6Y6DMAyE8/7PuGURV8gF5A5QaVb2tlV/WJbjePx5hHMO3nvknFFrRWsN13Vx3Pf9ye/4fv8OmjvPE0JrDaUUpFzQPX5grcNxHLDWYt93XqSVwnF4bNvGNfVSziCYECL/p/eUEoSUEtZqhFgxyQ2tFpRcUEtFCAH3dWNdV+y8xDCJMRqtVRYgWiJ7Pp+8VBAZDXgfoLRjmr7vsSwLk5+tcSZB5yzbQsKlFF5IYiRMgikmiGEYME0TN7vuAWMMxmHAo+vQ//6iloJ5nvlMpVbEGLGuEjEGtoSEYwhMThqCPpIINTjnzJ6QX9YY5Jz4RK6t5SEi9OHfU1rwJiUfBRmrjWbKcRxBxHTiNM+8QCn9GQqv/KlDgH9lEmvtxB/UWxV9Yme2owAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4cc395b4c6890e594af144bc503ebc30/e4706/INSTAR_Camera_Tool_03.avif 230w", "/en/static/4cc395b4c6890e594af144bc503ebc30/d1af7/INSTAR_Camera_Tool_03.avif 460w", "/en/static/4cc395b4c6890e594af144bc503ebc30/7f308/INSTAR_Camera_Tool_03.avif 920w", "/en/static/4cc395b4c6890e594af144bc503ebc30/68819/INSTAR_Camera_Tool_03.avif 934w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4cc395b4c6890e594af144bc503ebc30/a0b58/INSTAR_Camera_Tool_03.webp 230w", "/en/static/4cc395b4c6890e594af144bc503ebc30/bc10c/INSTAR_Camera_Tool_03.webp 460w", "/en/static/4cc395b4c6890e594af144bc503ebc30/966d8/INSTAR_Camera_Tool_03.webp 920w", "/en/static/4cc395b4c6890e594af144bc503ebc30/7d4d8/INSTAR_Camera_Tool_03.webp 934w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4cc395b4c6890e594af144bc503ebc30/81c8e/INSTAR_Camera_Tool_03.png 230w", "/en/static/4cc395b4c6890e594af144bc503ebc30/08a84/INSTAR_Camera_Tool_03.png 460w", "/en/static/4cc395b4c6890e594af144bc503ebc30/c0255/INSTAR_Camera_Tool_03.png 920w", "/en/static/4cc395b4c6890e594af144bc503ebc30/078fe/INSTAR_Camera_Tool_03.png 934w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4cc395b4c6890e594af144bc503ebc30/c0255/INSTAR_Camera_Tool_03.png",
              "alt": "INSTAR Camera Tool",
              "title": "INSTAR Camera Tool",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`After a successful update the button changes it's icon to an exclamation mark. Click it to reboot your camera:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0bc9d1a3741432ad71c4d567e6613c8f/6d2da/INSTAR_Camera_Tool_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "35.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAA7rAAAO6wFxzYGVAAABHElEQVQoz02Q2a7DIAxE+f9PbKVbKTtgyELI1khTjdNU98EaAfb4MCaIYBxHzPOMZVmwriv2ff/WgeM48H6/f3Wfqf9r2zadMc45tG2Lpq7RNA36vsc0TYixR0oJwzCAPdQYI3LOEBHMOSOEqL184z2hDM2sbVF3I/o4Yk5Jt90kbOq6VheJeH0T71TZy55933CeJ4ZhhGFzXVfwEuG9h+2sKilIxHLWqmEIopHQeFkXpeM3uZSGKc0wZVmiKArEGPB6/alRWRZ4PB54Pp9q1DY1vAis7TQGQlD5xtzTNCkxFxjxXom4mcosmMn1RdEB75yS8swhEl45RzX+keYME0KA8w5VVeGmtdbqWSOw9juUfsO38u7SSUG2bccHvZoVYyfWCG8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0bc9d1a3741432ad71c4d567e6613c8f/e4706/INSTAR_Camera_Tool_04.avif 230w", "/en/static/0bc9d1a3741432ad71c4d567e6613c8f/d1af7/INSTAR_Camera_Tool_04.avif 460w", "/en/static/0bc9d1a3741432ad71c4d567e6613c8f/7f308/INSTAR_Camera_Tool_04.avif 920w", "/en/static/0bc9d1a3741432ad71c4d567e6613c8f/1d2c7/INSTAR_Camera_Tool_04.avif 936w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0bc9d1a3741432ad71c4d567e6613c8f/a0b58/INSTAR_Camera_Tool_04.webp 230w", "/en/static/0bc9d1a3741432ad71c4d567e6613c8f/bc10c/INSTAR_Camera_Tool_04.webp 460w", "/en/static/0bc9d1a3741432ad71c4d567e6613c8f/966d8/INSTAR_Camera_Tool_04.webp 920w", "/en/static/0bc9d1a3741432ad71c4d567e6613c8f/40732/INSTAR_Camera_Tool_04.webp 936w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0bc9d1a3741432ad71c4d567e6613c8f/81c8e/INSTAR_Camera_Tool_04.png 230w", "/en/static/0bc9d1a3741432ad71c4d567e6613c8f/08a84/INSTAR_Camera_Tool_04.png 460w", "/en/static/0bc9d1a3741432ad71c4d567e6613c8f/c0255/INSTAR_Camera_Tool_04.png 920w", "/en/static/0bc9d1a3741432ad71c4d567e6613c8f/6d2da/INSTAR_Camera_Tool_04.png 936w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0bc9d1a3741432ad71c4d567e6613c8f/c0255/INSTAR_Camera_Tool_04.png",
              "alt": "INSTAR Camera Tool",
              "title": "INSTAR Camera Tool",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The `}<strong parentName="p">{`IP Config`}</strong>{` tab allows you to select a camera, authenticate with your camera admin account and change the network configuration:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/89a0601be2b4f30acb2f8cb440891a74/248b0/INSTAR_Camera_Tool_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "35.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABTElEQVQozz2R0W6jMBBF+f/fSxWyaZSExYBxANtjgoGmOis77T4c3RlrNLrXU9RKofueabJY55AQiOvKEiPruhLjmvsY47v+Yfnpt23Lc8uyZArrPG3bMVlH12nu9wovwjhZzGPAOY9IwFqXNfVeAhLmPHO73ajrmq7rGMeRIm1/GENcngTxNKrGWUsQwfQ9s0gmeM9zDnnua995fe1Ya/n4+EBrnRf2fU9hZUbpHhtmBifc64Z+tDysp9GG0UlmsJ7JByaZCXHDLxEzThwOh7woLTXGUDxC5FNp1OipHpZT1WT9Ozg+mx41CWoU6sHT2EDrnpjnhpk39OT5czqR7qCUom1bin3fmUNAxOeYKYb4d/0bPfH7FoLkyN+vF2Ges7vkLOkwDBRVVZE4n88cy5KyLLlcLhyPJdfrNfN20GQXSdN/pQMm1Qmt//MPL1YPsgHUmroAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/89a0601be2b4f30acb2f8cb440891a74/e4706/INSTAR_Camera_Tool_05.avif 230w", "/en/static/89a0601be2b4f30acb2f8cb440891a74/d1af7/INSTAR_Camera_Tool_05.avif 460w", "/en/static/89a0601be2b4f30acb2f8cb440891a74/7f308/INSTAR_Camera_Tool_05.avif 920w", "/en/static/89a0601be2b4f30acb2f8cb440891a74/c300c/INSTAR_Camera_Tool_05.avif 1316w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/89a0601be2b4f30acb2f8cb440891a74/a0b58/INSTAR_Camera_Tool_05.webp 230w", "/en/static/89a0601be2b4f30acb2f8cb440891a74/bc10c/INSTAR_Camera_Tool_05.webp 460w", "/en/static/89a0601be2b4f30acb2f8cb440891a74/966d8/INSTAR_Camera_Tool_05.webp 920w", "/en/static/89a0601be2b4f30acb2f8cb440891a74/c5455/INSTAR_Camera_Tool_05.webp 1316w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/89a0601be2b4f30acb2f8cb440891a74/81c8e/INSTAR_Camera_Tool_05.png 230w", "/en/static/89a0601be2b4f30acb2f8cb440891a74/08a84/INSTAR_Camera_Tool_05.png 460w", "/en/static/89a0601be2b4f30acb2f8cb440891a74/c0255/INSTAR_Camera_Tool_05.png 920w", "/en/static/89a0601be2b4f30acb2f8cb440891a74/248b0/INSTAR_Camera_Tool_05.png 1316w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/89a0601be2b4f30acb2f8cb440891a74/c0255/INSTAR_Camera_Tool_05.png",
              "alt": "INSTAR Camera Tool",
              "title": "INSTAR Camera Tool",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The `}<strong parentName="p">{`Update`}</strong>{` tab allows you to install a firmware and webUI update on your camera. `}<a parentName="p" {...{
        "href": "https://wiki.instar.com/dl/"
      }}>{`Download`}</a>{` the newest firmware version for your camera model, unzip the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`*.pkg`}</code>{` file and upload it through the camera tool:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8e9910f7a8a270966eb53b99fc65de09/248b0/INSTAR_Camera_Tool_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "35.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABVklEQVQoz0WS246jMBBE+f+vi3akoM2NhIttwOArGJKHs8Izu/tQqip3q+xuuWi7DiEkszGEELHOsW0badtYU/rW6b9Pf31GYt/33HPU1nWl6IRg1FMO1dPMOGqk6rP2PmTtfGA6LoyRaZ4JccFYh7GW+/2OEoLh9UIPA4WSkuA9Zp4IwRODR4qO43zfUuYlBuZ5Yktr7tu3jfd7xznHr68v2ralaRqklBRNJ5HjhBhGRuOYfaSViloobFwyzz6g9IRb1h9OuHVjso7T6ZTDuq5DKUVxqTtq7XjIkddoqLXl2iourUK6hd+NpLOBqtcov/JQByekTww2cL1eeL3qHCqEoDiefYzsnMX7b22NyYghYMz8r374tK583m8+nw8xRvq+ZxiGzFpriufzyaOquFyvnMuS87nkdrtRliX3x4OqqmjaluM3HLuSUiHVD6TMO85QKo/8B3UPDjy/fCABAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8e9910f7a8a270966eb53b99fc65de09/e4706/INSTAR_Camera_Tool_06.avif 230w", "/en/static/8e9910f7a8a270966eb53b99fc65de09/d1af7/INSTAR_Camera_Tool_06.avif 460w", "/en/static/8e9910f7a8a270966eb53b99fc65de09/7f308/INSTAR_Camera_Tool_06.avif 920w", "/en/static/8e9910f7a8a270966eb53b99fc65de09/c300c/INSTAR_Camera_Tool_06.avif 1316w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8e9910f7a8a270966eb53b99fc65de09/a0b58/INSTAR_Camera_Tool_06.webp 230w", "/en/static/8e9910f7a8a270966eb53b99fc65de09/bc10c/INSTAR_Camera_Tool_06.webp 460w", "/en/static/8e9910f7a8a270966eb53b99fc65de09/966d8/INSTAR_Camera_Tool_06.webp 920w", "/en/static/8e9910f7a8a270966eb53b99fc65de09/c5455/INSTAR_Camera_Tool_06.webp 1316w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8e9910f7a8a270966eb53b99fc65de09/81c8e/INSTAR_Camera_Tool_06.png 230w", "/en/static/8e9910f7a8a270966eb53b99fc65de09/08a84/INSTAR_Camera_Tool_06.png 460w", "/en/static/8e9910f7a8a270966eb53b99fc65de09/c0255/INSTAR_Camera_Tool_06.png 920w", "/en/static/8e9910f7a8a270966eb53b99fc65de09/248b0/INSTAR_Camera_Tool_06.png 1316w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8e9910f7a8a270966eb53b99fc65de09/c0255/INSTAR_Camera_Tool_06.png",
              "alt": "INSTAR Camera Tool",
              "title": "INSTAR Camera Tool",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`On the `}<strong parentName="p">{`SD Card`}</strong>{` tab you can `}<em parentName="p">{`unmount`}</em>{`, `}<em parentName="p">{`format`}</em>{` your camera's SD card:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b1d26221b9d0946190b5ad393c081935/248b0/INSTAR_Camera_Tool_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "35.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABUklEQVQozz2RaW6DMBCFuf8N2nO0PUX6J6qqkpJgVrMZGzAxEMhrZ7JYeralGY+/eeOVRYm6rtG2Lay10FqjqSt0WmPoO5zHEZM74zxaTNOEbds4j+6keZ75dM6xvCzL0DQNkiSBUgpFUUBqC+VWtO6CwljUg0O3AF/fP3h9fUEURRiGgSGEEKiq6gnlFVJyUOsbIQWCxiG2G+r5iqAyOEiFegF+wwgf72+cs64rjDHY7/dI0xShEAzl0cZUMmfCuiph7ITlsoFW3xlopXBdL3gsIiIIyv/c7ZhSPAvGMaOWZXFvWaIdZkz398Z0aJS6FR8G5HmOsiwxjiMTHo9HLkTWEZhHQ6BAq1uYrmM/oyRDFCdIkhRCRDidQmR5jiRN4fs+D25ZFnR9z0XoAyklW+HRhTwg5CAIWCIMcfB9Nj+OY5DPlEeP6UPq5CHq7iH9P5Q/xXoND+GWiBgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b1d26221b9d0946190b5ad393c081935/e4706/INSTAR_Camera_Tool_07.avif 230w", "/en/static/b1d26221b9d0946190b5ad393c081935/d1af7/INSTAR_Camera_Tool_07.avif 460w", "/en/static/b1d26221b9d0946190b5ad393c081935/7f308/INSTAR_Camera_Tool_07.avif 920w", "/en/static/b1d26221b9d0946190b5ad393c081935/c300c/INSTAR_Camera_Tool_07.avif 1316w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b1d26221b9d0946190b5ad393c081935/a0b58/INSTAR_Camera_Tool_07.webp 230w", "/en/static/b1d26221b9d0946190b5ad393c081935/bc10c/INSTAR_Camera_Tool_07.webp 460w", "/en/static/b1d26221b9d0946190b5ad393c081935/966d8/INSTAR_Camera_Tool_07.webp 920w", "/en/static/b1d26221b9d0946190b5ad393c081935/c5455/INSTAR_Camera_Tool_07.webp 1316w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b1d26221b9d0946190b5ad393c081935/81c8e/INSTAR_Camera_Tool_07.png 230w", "/en/static/b1d26221b9d0946190b5ad393c081935/08a84/INSTAR_Camera_Tool_07.png 460w", "/en/static/b1d26221b9d0946190b5ad393c081935/c0255/INSTAR_Camera_Tool_07.png 920w", "/en/static/b1d26221b9d0946190b5ad393c081935/248b0/INSTAR_Camera_Tool_07.png 1316w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b1d26221b9d0946190b5ad393c081935/c0255/INSTAR_Camera_Tool_07.png",
              "alt": "INSTAR Camera Tool",
              "title": "INSTAR Camera Tool",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Clicking on the `}<strong parentName="p">{`Browse`}</strong>{` button allows you to select a date and batch download all recorded files from the selected date:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/50e0661d3274d4404496213256dd1e00/248b0/INSTAR_Camera_Tool_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "35.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABWElEQVQozz2R25LbIAyG/f5v0ote9D22F810dppMEmPjFIPNwRiMD9n5W2lnw8wPDBLSJ6nSvcYwDHDOIaUE7z1GYxBDgLMj7DigLBnrsiDOCYMNiHHGtm1Y1/V1llJY1ePxwDiOkFLCWou+76FCwrgcGPLO92Eu8OsTv36/49v3HxBNi3me4ZyHEALGmBdU1SvFRu8/CclwdwV/7IF7OHAfJtxMQG0TjPPYysKJj+NACAGn0wld16EWgqEq2phK/WVHYzRiXhHygVyeSHFilZyw7TtoGa0Zgvx/vr0xpXgFbFtG1br/LFkphLTBpidi+UCMEdMUsSwLl1jXNQPknJnwdrtxIGodvVc0BDI47xCmifvZygfqRkK0EkI0qGsB2XWQssP5fOHB7fuOKUYOorWGUorbVdGFekDI1+uVRRSXyxlN06BtW3YmcvpMCamSL1F1X/L/h/IPncQM6Ot4JdEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/50e0661d3274d4404496213256dd1e00/e4706/INSTAR_Camera_Tool_08.avif 230w", "/en/static/50e0661d3274d4404496213256dd1e00/d1af7/INSTAR_Camera_Tool_08.avif 460w", "/en/static/50e0661d3274d4404496213256dd1e00/7f308/INSTAR_Camera_Tool_08.avif 920w", "/en/static/50e0661d3274d4404496213256dd1e00/c300c/INSTAR_Camera_Tool_08.avif 1316w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/50e0661d3274d4404496213256dd1e00/a0b58/INSTAR_Camera_Tool_08.webp 230w", "/en/static/50e0661d3274d4404496213256dd1e00/bc10c/INSTAR_Camera_Tool_08.webp 460w", "/en/static/50e0661d3274d4404496213256dd1e00/966d8/INSTAR_Camera_Tool_08.webp 920w", "/en/static/50e0661d3274d4404496213256dd1e00/c5455/INSTAR_Camera_Tool_08.webp 1316w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/50e0661d3274d4404496213256dd1e00/81c8e/INSTAR_Camera_Tool_08.png 230w", "/en/static/50e0661d3274d4404496213256dd1e00/08a84/INSTAR_Camera_Tool_08.png 460w", "/en/static/50e0661d3274d4404496213256dd1e00/c0255/INSTAR_Camera_Tool_08.png 920w", "/en/static/50e0661d3274d4404496213256dd1e00/248b0/INSTAR_Camera_Tool_08.png 1316w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/50e0661d3274d4404496213256dd1e00/c0255/INSTAR_Camera_Tool_08.png",
              "alt": "INSTAR Camera Tool",
              "title": "INSTAR Camera Tool",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "accessing-your-cameras-sd-card",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#accessing-your-cameras-sd-card",
        "aria-label": "accessing your cameras sd card permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Accessing your Camera´s SD Card`}</h3>
    <VideoWindowsSD mdxType="VideoWindowsSD" />
    <EuiSpacer mdxType="EuiSpacer" />
    <VideoMacSD mdxType="VideoMacSD" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "alternative-ip-scanner",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#alternative-ip-scanner",
        "aria-label": "alternative ip scanner permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alternative IP Scanner`}</h3>
    <p>{`If you have problems finding your camera with the INSTAR Camera Tool, you can try one of the following alternative scanners:`}</p>
    <ScannerCards mdxType="ScannerCards" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "assigning-a-name",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#assigning-a-name",
        "aria-label": "assigning a name permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Assigning a Name`}</h3>
    <VideoWindowsDNS mdxType="VideoWindowsDNS" />
    <EuiSpacer mdxType="EuiSpacer" />
    <VideoMacDNS mdxType="VideoMacDNS" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      